/*** editable ***/

body {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #666666;
    font-weight: 300;
}

/* general content areas */

.mz_editable {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    color: #666666;
    font-weight: 300;;
}

.mz_editable b {
    font-weight: 600;
}

/* hyperlinks */

a, .mz_editable a {
    color: #4C92DA;
    text-decoration: none;
}

a:hover, .mz_editable a:hover {
    color: #666666;
}

/* bullet list in editable text */

.mz_editable ul li {
    list-style-type: square;
}

/* headings in editable text */

.mz_editable h1, .mz_editable h1.moze-megatitle {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 2.6rem;
    color: #4C92DA;
    font-weight: 400;
    letter-spacing: -0.02em;
}

.moze-megatitle {
    font-size: 3.13rem !important;
}

.mz_editable h2, .sidebox h2 {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    color: #383838;
    font-weight: 600;
    letter-spacing: -0.01em;
}

.mz_editable h3 {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    color: #383838;
    font-weight: 600;
}

/* special text styles */

.mz_editable .moze-blockquote {
    font-family: Georgia, serif
}

/* webforms */

.moze-form label {
    color: #383838
}

.moze-form input,
.moze-form select,
.moze-form textarea {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    border: 1px solid #DDDDDD;
    color: #666666;
    border-radius: 2px;
    font-weight: 300;
}

.moze-form .moze-formbutton {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    color: #FFFFFF;
    background-color: #4C92DA;
    border-radius: 3px;
}

.moze-form .moze-formbutton:hover {
    background-color: #3C424F;

}

/* blog and articles */

.moze-button,
.moze-button-large {
    background-color: #4C92DA;
    font-size: 16px;
    border-radius: 19px;
}

.moze-button:hover,
.moze-button-large:hover {
    background-color: #A9ADB4;
}

.moze-button-large.btn-white,
.moze-button.btn-alt,
.moze-button-large.btn-alt {
    color: #4C92DA;
}

.moze-button-large.btn-white:hover,
.moze-button.btn-alt:hover,
.moze-button-large.btn-alt:hover {
    color: #A9ADB4;
}

.moze-button-large.btn-alt-light {
    border-color: #4C92DA;
}

.moze-button-large.btn-alt-light:hover {
    border-color: #A9ADB4;
}

.moze-button-large, .moze-form .moze-formbutton  {
    font-size: 16px;
    border-radius: 5px;
}

/* list of recent blog posts */


/*@import url("social-icons/style.css");*/



/********************************/
/* General */
/********************************/

body {
    /*-webkit-text-size-adjust: none;*/
    padding: 0px;
    margin: 0px;
}

/* Chrome-Only hack */
@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:"*") {
    body {
        image-rendering: -webkit-optimize-contrast;
    }
}

body.mz_rtl {
    direction: rtl;
    unicode-bidi: embed;
}

iframe {
    max-width: 100%;
}

.clear {
    clear: both;
}

.cf:after {
    content: ' ';
    display: table;
    clear: both;
}

input.mz_notforhumans {
    position: absolute;
    display: block;
    width: 1px !important;
    height: 1px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: none !important;
    background-color: rgba(255, 255, 255, 0);
    opacity: 0.01; /* for macos safari */
}

a.mz_notforhumans {
    position: absolute;
    bottom: 0px;
    display: block;
    width: 1px !important;
    height: 1px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: none !important;
    overflow: hidden;
    color: rgba(0, 0, 0, 0);
}

.mz_notforhumans:focus {
    outline: 0;
}

img {
    border: none;
}

#title a {
    text-decoration: none;
}

/* to accommodate extra long words that break width */
.mz_editable h1 {
    word-wrap: break-word;
}

.mz_editable h1:first-child,
.mz_editable h2:first-child,
.mz_editable h3:first-child,
.mz_editable p {
    margin-top: 0;
}

/*.mz_editable h1:last-child,
.mz_editable h2:last-child,
.mz_editable h3:last-child,
.mz_editable p {
    margin-bottom: 0;
}*/


/********************************/
/* Formatting framework */
/********************************/

.mz_editable {
    line-height: 1.7;
}

.mz_editable b {
    font-weight: bold; /* override buggy Firefox behavior with invalid default styles for b */
}

.mz_editable p {
    margin-bottom: 1.6em;
}

.mz_editable p:last-child,
.mz_editable h1:last-child,
.mz_editable h2:last-child,
.mz_editable h3:last-child {
    margin-bottom: 0;
}

.mz_editable h1, .mz_editable h1.moze-megatitle {
    margin-bottom: 0.60em;
    margin-top: 0;
    line-height: 1.3;
}

.mz_editable p + h1 {
    margin-top: 0.80em;
}

.mz_editable h2, .sidebox h2 {
    margin-top: 0.85em;
    line-height: 1.3;
}

.mz_editable h2, .sidebox h2, .sidebox h2:last-child {
    margin-bottom: 0.65em;
}

.mz_editable h2:first-child, .sidebox h2 {
    margin-top: 0px;
}

.mz_editable h3 {
    line-height: 1.3;
}

.mz_editable .moze-tiny       { font-size: 0.80em; }
.mz_editable .moze-small      { font-size: 0.90em; }
.mz_editable .moze-normal     { font-size: 1.00em; }
.mz_editable .moze-large      { font-size: 1.10em; }
.mz_editable .moze-huge       { font-size: 1.22em; }
.mz_editable .moze-gigantic   { font-size: 1.36em; }

.mz_editable .moze-left    { text-align: left; }
.mz_editable .moze-center  { text-align: center; }
.mz_editable .moze-right   { text-align: right; }
.mz_editable .moze-justify { text-align: justify; }

.mz_editable .moze-secondary { font-size: 94%; }

/* blockquote */

.mz_editable .moze-blockquote.moze-tiny       { font-size: calc(0.80em*1.1); }
.mz_editable .moze-blockquote.moze-small      { font-size: calc(0.90em*1.1); }
.mz_editable .moze-blockquote.moze-normal     { font-size: calc(1.00em*1.1); }
.mz_editable .moze-blockquote.moze-large      { font-size: calc(1.10em*1.1); }
.mz_editable .moze-blockquote.moze-huge       { font-size: calc(1.22em*1.1); }
.mz_editable .moze-blockquote.moze-gigantic   { font-size: calc(1.36em*1.1); }

.mz_editable .moze-blockquote {
    font-style: italic;
    margin-bottom: 1.6em;
    display: block;
    padding: 0 2.2em 0 2.2em;
    position: relative;
    font-size: 1.1em;
}

.mz_editable .moze-blockquote:before {
    content: "\201C";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 4em;
    line-height: 1;
    opacity: 0.5;
    top: -0.05em;
}


/* general text and form text */

.section-text-color-1 .mz_editable .moze-wysiwyg-editor,
.section-text-color-1 .mz_editable .moze-wysiwyg-editor h1,
.section-text-color-1 .mz_editable .moze-wysiwyg-editor h2,
.section-text-color-1 .mz_editable .moze-wysiwyg-editor h3,
.section-text-color-1 .moze-form * {
    color: #FFFFFF;
}

.design-inverted .section-text-color-1 .mz_editable .moze-wysiwyg-editor,
.design-inverted .section-text-color-1 .mz_editable .moze-wysiwyg-editor h1,
.design-inverted .section-text-color-1 .mz_editable .moze-wysiwyg-editor h2,
.design-inverted .section-text-color-1 .mz_editable .moze-wysiwyg-editor h3,
.design-inverted .section-text-color-1 .moze-form * {
    color: rgba(0, 0, 0, 0.75);
}

/* store items */

.section-text-color-1 .cat-thumb-title,
.section-text-color-1 .cat-thumb-price {
    color: #FFFFFF;
}

.design-inverted .section-text-color-1 .cat-thumb-title,
.design-inverted .section-text-color-1 .cat-thumb-price {
    color: rgba(0, 0, 0, 0.75);
}

/* links */

.section-text-color-1 .mz_editable .moze-wysiwyg-editor a {
    color: rgba(255, 255, 255, 0.6);
}

.design-inverted .section-text-color-1 .mz_editable .moze-wysiwyg-editor a {
    color: rgba(0, 0, 0, 0.5);
}

/* important text */

.mz_editable .moze-important {
    background-color: rgba(0, 0, 0, 0.07);
    color: rgba(0, 0, 0, 0.95);
}

.section-text-color-1 .mz_editable .moze-important,
.design-inverted .section:not(.section-text-color-1) .mz_editable .moze-important {
    background-color: rgba(255, 255, 255, 0.17);
    color: #FFFFFF;
}

/* secondary text */

.mz_editable .moze-secondary {
    color: rgba(0,0,0,0.4);
}

.section-text-color-1 .mz_editable .moze-secondary,
.design-inverted .section:not(.section-text-color-1) .mz_editable .moze-secondary {
    color: rgba(255, 255, 255, 0.7);
}

/* code - normal */

.mz_editable .moze-code {
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.4;
    padding: 8px;
    display: block;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 16px 0px 16px 0px;
    color: rgba(0, 0, 0, 0.5);
    border: 0px solid rgba(150, 150, 150, 0.1);
}

/* code - for dark background */

body:not(.design-inverted) .section-text-color-1 .mz_editable .moze-code,
.design-inverted .section:not(.section-text-color-1) .mz_editable .moze-code {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
}

/* tables - normal */

.mz_editable table {
    border-spacing: 0px;
    /*border-collapse: collapse;*/
}

.mz_editable table td {
    padding: 9px;
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mz_editable table .moze-th {
    background-color: rgba(0, 0, 0, 0.05);
}

/* tables - for dark background  */

body:not(.design-inverted) .section-text-color-1 .mz_editable table td,
.design-inverted .mz_editable table td {
    border-color: rgba(255, 255, 255, 0.1);
}

body:not(.design-inverted) .section-text-color-1 .mz_editable table .moze-th,
.design-inverted .mz_editable table .moze-th {
    background-color: rgba(255, 255, 255, 0.08);
}

/* forms - for dark background */

.section-text-color-1 .moze-form input,
.section-text-color-1 .moze-form textarea,
.section-text-color-1 .moze-form select {
    border: 0;
    background-color: rgba(255, 255, 255, 0.2);
}

.section-text-color-1 .moze-form option {
    color: #222222;
}

/* forms - for light background */

.design-inverted .section-text-color-1 .moze-form input,
.design-inverted .section-text-color-1 .moze-form textarea,
.design-inverted .section-text-color-1 .moze-form select {
    border: 0;
    background-color: rgba(0, 0, 0, 0.1);
}

.design-inverted .section-text-color-1 .moze-form option {
    color: rgba(0, 0, 0, 0.75);
}

/* buttons - for dark background */

.section-text-color-1 .mz_editable .moze-formbutton:not(.btn-alt),
.section-text-color-1 .mz_editable .moze-button:not(.btn-alt),
.section-text-color-1 .mz_editable .moze-button-large:not(.btn-alt),
.section-text-color-1 .mz_editable .moze-formbutton:not(.btn-alt):hover,
.section-text-color-1 .mz_editable .moze-button:not(.btn-alt):hover,
.section-text-color-1 .mz_editable .moze-button-large:not(.btn-alt):hover {
    background-color: #FFFFFF;
    color: #222222;
}

.section-text-color-1 .mz_editable .moze-button.btn-alt,
.section-text-color-1 .mz_editable .moze-button-large.btn-alt {
    color: #FFFFFF;
}

/* buttons - for light background */

.design-inverted .section-text-color-1 .mz_editable .moze-formbutton:not(.btn-alt),
.design-inverted .section-text-color-1 .mz_editable .moze-button:not(.btn-alt),
.design-inverted .section-text-color-1 .mz_editable .moze-button-large:not(.btn-alt),
.design-inverted .section-text-color-1 .mz_editable .moze-formbutton:not(.btn-alt):hover,
.design-inverted .section-text-color-1 .mz_editable .moze-button-large:not(.btn-alt):hover {
    background-color: rgba(0, 0, 0, 0.75);
    color: #FFFFFF;
}

.design-inverted .section-text-color-1 .mz_editable .moze-button.btn-alt,
.design-inverted .section-text-color-1 .mz_editable .moze-button-large.btn-alt {
    color: rgba(0, 0, 0, 0.75);
}

/* buttons - for both */

.section-text-color-1 .mz_editable .moze-formbutton:not(.btn-alt):hover,
.section-text-color-1 .mz_editable .moze-button:not(.btn-alt):hover,
.section-text-color-1 .mz_editable .moze-button-large:not(.btn-alt):hover {
    opacity: 0.8;
}

.section-text-color-1 .mz_editable .moze-button.btn-alt:hover,
.section-text-color-1 .mz_editable .moze-button-large.btn-alt:hover {
    opacity: 0.7;
}


/********************************/
/* Banner */
/********************************/

#bigbar.keep-aspect {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#bigbar .mz_banner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.mz_banner .moze-banner {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.mz_banner .moze-banner {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.mz_banner .moze-banner-slide-left,
.mz_banner .moze-banner-slide-right
{
    background: rgba(0, 0, 0, 0.4) no-repeat center center;
    background-size: 25px;
    position: absolute;
    opacity: 0;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    display: block;
    z-index: 50;
}

.mz_banner .moze-banner-slide-left {
    background-image: url("../../../libs/icons/icon-arrow-left-white.svg");
    left: 0;
}

.mz_banner .moze-banner-slide-right {
    background-image: url("../../../libs/icons/icon-arrow-right-white.svg");
    right: 0;
}

div:hover > .mz_banner .moze-banner-slide-right,
div:hover > .mz_banner .moze-banner-slide-left
{
    opacity: 1;
}

@media screen and (max-width: 600px) {
    div:hover > .mz_banner .moze-banner-slide-right,
    div:hover > .mz_banner .moze-banner-slide-left
    {
        opacity: 0;
    }
}

.bigbar-h1 {
    word-wrap: break-word;
}

.bigbar-h1 .mz_wysiwyg {
    margin: 0px;
    line-height: 1.3;
}

.bigbar-h2 .mz_wysiwyg {
    line-height: 1.5;
}

#bigbar-down {
    animation: jumpy 0.5s ease 1s infinite alternate;
    -webkit-animation: jumpy 0.5s ease 1s infinite alternate; /* Safari and Chrome */
}

.bigbar-overlay-buttons {
    text-align: center;
    left: 0;
    right: 0;
    padding-top: 40px;
    /*display: none;*/
}

/* if buttons are not part of central alignment layout,
do not center, otherwise always center */
.bigbar-overlay-container:not(.overlay-align-center) .bigbar-overlay-buttons {
    text-align: inherit;
}

.bigbar-overlay-container {
    position: relative;
    display: flex;
    flex-direction: column;
    /*min-height: 350px;*/
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
}

@media screen and (max-width: 1200px) {
    .keep-aspect .bigbar-overlay-container {
        min-height: 370px !important;
    }
}

@media screen and (min-width: 1800px) {
    .keep-aspect .bigbar-overlay-container {
        min-height: 580px !important;
    }
}

[class*="overlay-align-"] {
    padding: 40px;
    max-width: 80%;
}

.overlay-align-center {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.overlay-align-center .bigbar-h1 .mz_wysiwyg,
.overlay-align-center .bigbar-h2 .mz_wysiwyg {
    text-align: center;
    float: none;
}

.overlay-align-center .bigbar-h1,
.overlay-align-center .bigbar-h2 {
    float: none;
}

.overlay-align-left {
    justify-content: flex-end;
    align-items: flex-start !important;
    max-width: 45%;
    text-align: left !important;
}

.bigbar-overlay-container .overlay-align-left #bigbar-overlay {
    text-align: left;
    align-items: flex-start;
}

.overlay-align-right {
    justify-content: flex-end;
    align-items: flex-start;
    max-width: 45%;
    left: auto;
    float: right;
}

#bigbar-container:after, #bigbar:after {
    content: " ";
    display: table;
    clear: both;
}

.overlay-align-right #bigbar-overlay {
    text-align: left;
}


#bigbar-overlay {
    padding: 25px;
    box-sizing: border-box;
}

.bigbar-h1 .mz_wysiwyg {
    margin-bottom: 0.2em;
}

@media screen and (max-width: 1200px) {

    .overlay-align-left, .overlay-align-right {
        max-width: 50%;
    }

}

@media screen and (max-width: 1024px) {

    [class*="overlay-align-"] {
        justify-content: flex-end;
    }

    .overlay-align-left, .overlay-align-right {
        max-width: 60%;
    }

}

@media screen and (min-width: 751px) {

    .bigbar-overlay-container.overlay-with-buttons {
        padding: 50px;
        justify-content: center;
    }

}

@media screen and (max-width: 750px) {

    [class*="overlay-align-"] {
        padding: 20px;
    }

    .bigbar-overlay-container {
        min-height: auto !important;
    }

    .bigbar-overlay-container.overlay-with-buttons {
        padding: 20px;
    }

    .bigbar-overlay-buttons {
        padding-top: 20px;
    }

    .overlay-align-center {
        max-width: none;
    }

    .overlay-align-left, .overlay-align-right {
        max-width: 70%;
    }

    #bigbar-overlay {
        margin-top: 230px !important;
        padding: 20px;
    }

}

@media screen and (max-width: 500px) {

    .overlay-align-center, .overlay-align-left, .overlay-align-right {
        max-width: none;
    }

}


@keyframes jumpy {
    from {
        background-position: center 0px;
    }
    to {
        background-position: center 10px;
    }
}
@-webkit-keyframes jumpy { /* Safari and Chrome */
    from {
        background-position: center 0px;
    }
    to {
        background-position: center 10px;
    }
}


/********************************/
/* Header, title, toptext, languages */
/********************************/

.design-boxed #wrap {
    margin: 0 auto;
    max-width: 1260px;
}

#header, #bottom, #bigbar-container, #menu-container, #submenu {
    margin: 0 auto;
    clear: both;
    padding: 20px 40px;
}

#bottom {
    padding: 40px;
}

#header {
    transition: padding 0.1s;
}

#header:after {
    content: ' ';
    display: table;
    clear: both;
}

#bigbar-container, #submenu {
    padding-top: 0px;
    padding-bottom: 0px;
}

#submenu > ul {
    margin-left: -14px;
    margin-right: -14px;
}

.container, #header, #bottom, #bigbar-container, #menu-container, #submenu {
    max-width: 1260px;
    box-sizing: border-box;
}

.design-boxed #bottom {
    box-sizing: content-box;
}

[class*="header-menu-"].design-boxed #header, .design-boxed #submenu {
    box-sizing: content-box;
}

#title, #toptext {
    clear: both;
    text-align: center;
}

#title .mz_wysiwyg, #toptext .mz_wysiwyg {
    margin: 0 auto;
    display: inline-block;
}

#title .moze-wysiwyg-editor {
    min-height: 30px;
    min-width: 1em;
}

/* Toptext */

#toptext .mz_wysiwyg {
    margin: 11px 0px;
}

#toptext .mz_wysiwyg a {
    opacity: 0.65;
}

/* Languages */

#languages {
    float: right;
}

.mz_rtl #languages {
    float: left;
}

#languages {
    margin-left: 35px;
}

.mz_rtl #languages {
    margin-left: 0;
    margin-right: 35px;
}

#languages ul {
    list-style: none outside none;
    padding: 0px;
    margin: 0px;
}

#languages li {
    display: inline-block;
    line-height: 1;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

/* Header */

#header::after {
    content: " ";
    clear: both;
    display: table;
}

#top, #bottom {
    /* necessary for backend to position editing button, but cannot use .backend to avoid override */
    position: relative;
}

.transparentnavi #wrap {
    position: relative;
}

.transparentnavi #top {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: transparent;
    border: 0;
}

.transparentnavi #top:not(.sticky).section-text-color-1 #menu > ul > li > a,
.transparentnavi #top:not(.sticky).section-text-color-1 #menu > ul > li > a:hover,
.transparentnavi #top:not(.sticky).section-text-color-1 #menu > ul > li.selected > a,
.transparentnavi #top:not(.sticky).section-text-color-1 #languages li > a,
.transparentnavi #top:not(.sticky).section-text-color-1 .mz_wysiwyg {
    color: #FFFFFF;
    background-color: transparent;
}

.transparentnavi #top:not(.sticky).section-text-color-1 #menu > ul > li > a:hover,
.transparentnavi #top:not(.sticky).section-text-color-1 #menu > ul > li.selected > a,
.transparentnavi #top:not(.sticky).section-text-color-1 #languages li.selected > a,
.transparentnavi #top:not(.sticky).section-text-color-1 #languages li > a:hover {
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
}

/* Switchable menu layouts for the top of the page */

@media screen and (min-width: 751px) and (min-height:501px) {

    #header-main {
        min-height: 74px;
    }

    #top.sticky #header-main {
        min-height: auto;
    }

    [class*="header-menu-"] #header {
        display: flex;
        position: relative;
        flex-wrap: wrap-reverse;
        align-items: center;
        justify-content: flex-end;
        align-content: end;
    }

    [class*="header-menu-"] #header-main {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
    }

    .header-menu-center #header-main {
        flex: 1 1 100%;
        justify-content: center;
    }

    .header-menu-begin #header-main,
    .header-menu-down #header-main {
        justify-content: flex-start;
    }

    .header-menu-corner #header-main {
        max-width: 66.6%;
        flex-wrap: nowrap;
    }

    .header-menu-corner #header {
        justify-content: space-between;
    }

    .header-menu-corner #title {
        order: 2;
        margin-left: 0;
        margin-right: 0;
    }

    .header-menu-corner #menu,
    .header-menu-corner #title {
        width: 50%;
    }



    .header-menu-corner #menu > ul {
        text-align: initial;
    }

    [class*="header-menu-"] #languages {
        order: 2;
        flex: 0 1 auto;
    }

    .header-menu-begin #languages,
    .header-menu-end #languages {
        margin: 0;
        margin-left: 2rem;
    }

    .mz_rtl.header-menu-begin #languages,
    .mz_rtl.header-menu-end #languages {
        margin: 0;
        margin-right: 2rem;
    }

    .header-menu-center #languages {
        margin: 0;
    }

    .header-menu-down #languages,
    .header-menu-begin .menu-wrapped #languages,
    .header-menu-end .menu-wrapped #languages  {
        position: absolute;
        right: 40px;
        top: 0px;
        margin-top: 20px;
    }

    .header-menu-center #toptext,
    .header-menu-begin #toptext,
    .header-menu-end #toptext,
    .header-menu-corner #toptext {
        display: none;
    }

    .mz_rtl.header-menu-down #languages,
    .mz_rtl.header-menu-begin .menu-wrapped #languages,
    .mz_rtl.header-menu-end .menu-wrapped #languages  {
        right: auto;
        left: 40px;
    }

    [class*="header-menu-"]:not(.mz_rtl):not(.header-menu-center):not(.header-menu-corner) #title {
        margin-right: 2.6rem;
    }

    [class*="header-menu-"].mz_rtl:not(.header-menu-center):not(.header-menu-corner) #title {
        margin-left: 2.6rem;
    }

    .header-menu-center #title {
        margin-left: 0;
        margin-right: 0;
        flex: 1 1 100%;
        text-align: center;
    }

    .header-menu-center #title .mz_wysiwyg {
        display: inline-block;
    }

    /*
    .header-menu-down #title,
    .header-menu-begin .menu-wrapped #title,
    .header-menu-end .menu-wrapped #title {
        -margin-top: 0px;
    }
    */

    .header-menu-center #toptext {
        text-align: center;
    }

    .header-menu-begin #menu,
    .header-menu-end #menu {
        flex: 1 1 auto;
    }

    .header-menu-center #menu,
    .header-menu-down #menu {
        flex: 1 1 100%;
    }

    .header-menu-center #menu {
        display: flex;
        justify-content: center;
    }

    .header-menu-end #menu > ul {
        text-align: right
    }

    .mz_rtl.header-menu-end #menu > ul {
        text-align: left
    }

    .header-menu-begin #menu > ul,
    .header-menu-down #menu > ul,
    .header-menu-end .menu-wrapped #menu > ul {
        text-align: left;
    }

    .mz_rtl.header-menu-begin #menu > ul,
    .mz_rtl.header-menu-down #menu > ul,
    .mz_rtl.header-menu-end .menu-wrapped #menu > ul {
        text-align: right;
    }

}

/* fixed menu for top */

@media screen and (min-width: 751px) and (min-height:501px) {

    body:not(.mobile-header) #top.sticky:not(.legacy-sticky-menu),
    body:not(.mobile-header) #top.sticky.legacy-sticky-menu #menu {
        left: 0;
        right: 0;
        top: 0;
        bottom: auto !important;
        position: fixed !important;
        z-index: 51;
        box-shadow: 0px 1px 5px rgba(0,0,0,0.17);
        -webkit-backface-visibility: hidden;   /* fix for Chrome scrolling bug */
    }

    #mz_customizer[style*="block"] ~ #top.sticky:not(.legacy-sticky-menu),
    #mz_customizer[style*="block"] ~ #top.sticky.legacy-sticky-menu #menu,
    #mz_customizer[style*="block"] ~ div #top.sticky:not(.legacy-sticky-menu),
    #mz_customizer[style*="block"] ~ div #top.sticky.legacy-sticky-menu #menu {
        left: 200px !important;
    }

    body.backend:not(.mobile-header) #top.sticky:not(.legacy-sticky-menu),
    body.backend:not(.mobile-header) #top.sticky.legacy-sticky-menu #menu {
        left: 95px !important;
    }

    body:not(.mobile-header) #top.sticky.legacy-sticky-menu #menu {
        margin-top: 0;
    }

    #top.sticky.is-semifat #languages,
    #top.sticky.is-fat #languages,
    #top.sticky.is-fat #title,
    #top.sticky.is-fat #toptext,
    #top.sticky.has-menubox #header,
    #top.sticky.is-fat #bigbar-overlay .bigbar-h1 {
        display: none;
    }

    #top.scrolled-deep:not(.legacy-sticky-menu) #header {
        padding-top: 0px;
        padding-bottom: 0px;
        /*transition: padding 0.3s;*/
    }

    #top.scrolled-deep.resize-logo:not(.legacy-sticky-menu) #title .mz_wysiwyg img {
        max-height: 50px;
        width: auto !important;
    }

    #top.scrolled-deep #title {
        display: flex;
        align-items: center;
        min-height: 40px;
    }

}

@media screen and (max-width: 750px), screen and (max-height:500px) {

    #languages, .mz_rtl #languages {
        float: none;
        text-align: center;
        margin: 0 !important;
        padding: 0 !important;
    }

    #languages ul, .mz_rtl #languages ul {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 5px !important;
        margin-bottom: 25px !important;
    }

}



/********************************/
/* Menu Bones */
/********************************/

/* All levels */

.mz_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mz_menu ul:after {
    content: "";
    clear: both;
    display: block;
}

#menu ul li {
    display: block;
    float: left;
    text-align: center;
    line-height: 1;
}

.mz_rtl #menu ul li {
    float: right;
}

/* Top level */

#menu > ul > li,
.mz_rtl #menu > ul > li {
    position: relative;
    display: inline-block;
    float: none;
}

#menu > ul > li > a {
    display: inline-block;
}

/* Popup mechanism */

#menu ul ul {
    display: none;
    z-index: 500;
}

#menu ul li:hover > ul {
    display: block;
}

/* Lower levels */

#menu ul ul li a {
    display: block;
}

#menu ul ul {
    position: absolute;
    top: 100%;
    min-width: 150px;
}

#menu ul ul li {
    float: none;
    position: relative;
    margin: 0px;
    white-space: nowrap;
    text-align: left;
}

.mz_rtl #menu ul ul li {
    float: none;
    text-align: right;
}

#menu ul ul ul {
    position: absolute;
    left: 100%;
    top:0;
}

.mz_rtl #menu ul ul ul {
    left: auto;
    right: 100%;
}

/* menu definitions for mobile phones */

@media screen and (max-width: 750px), screen and (max-height:500px) {

    #menu > ul > li {
        display: inline-block !important;
        float: none !important;
    }

    #menu {
        width: auto;
        text-align: center;
    }

    .mobile-header #menu {
        text-align: center;
    }

    #menu-placeholder {
        display: none;
    }

}



/********************************/
/* Menu Design & Burger Menu */
/********************************/

#menu {
    line-height: 1;
    clear: both;
}

#menu > ul {
    text-align: center;
}

#menu:after {
    content: ' ';
    display: table;
    clear: both;
}

#menu-container {
    padding-top: 0px;
    padding-bottom: 0px;
}

.mobile-menu-closer {
    display: none !important;
}

#menu-opener,
#languages-opener,
.mobile-menu-arrow {
    display: none;
}

@media screen and (max-width: 750px), screen and (max-height:500px) {

    .mobile-header #menu-container {
        padding: 0;
    }

    .mobile-header #languages ul li {
        text-align: right;
        display: block;
    }
    
    .mobile-header.mz_rtl #languages ul li {
        text-align: left;
    }            
    
    .mobile-header #languages ul li > a {
        font-size: 16px;
    }
    
    #languages ul li.mobile-menu-closer a {
        text-align: right;
    }
    
    .mz_rtl #languages ul li.mobile-menu-closer a {
        text-align: left;
    }            
    
    .mobile-menu-opener {
        width: 55px;
        height: 55px;
        cursor: pointer;
    }

    .mobile-menu-opener svg * {
        transition: fill 0.5s ease;
    }

    #mobile-menu-bar {
        display: none;
        /*background-color: none;
        left: 0px;
        right: 0px;
        width: auto;
        height: 0;
        z-index: 51;
        position: fixed;
        transition: background-color 0.5s ease;*/
    }

    #menu-opener {
        z-index: 52;
        order: 0;
    }    
    
    #languages-opener { 
        z-index: 53;
        order: 2;
    }
    
    #menu-opener,
    #languages-opener {
        display: block;
    }

    .mobile-menu-closer {
        display: block !important;
    }

    .mobile-menu-opener img,
    .mobile-menu-opener svg {
        width: 55px;
        height: 55px;
        padding: 16px;
        box-sizing: border-box;
    }

    .mobile-menu-closer img,
    .mobile-menu-closer svg {
        width: 23px;
        height: 23px;
        box-sizing: border-box;
    }

    .mobile-menu-closer a {    
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    
    #menu > ul > li > a {
        position: relative;
    }     
    
    .mobile-menu-arrow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 55px;
    }

    .mobile-menu-arrow img, .mobile-menu-arrow svg {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 100%;
        max-height: 100%;
        padding: 14px;
        box-sizing: border-box;
    }
    
    .mz_rtl .mobile-menu-arrow, 
    .mz_rtl .mobile-menu-arrow img, 
    .mz_rtl .mobile-menu-arrow svg {
        left: 0;
        right: auto;
    }

    .mobile-header #menu,
    .mobile-header #languages,
    .mobile-header #submenu > ul {
        display: none;
        position: fixed;
        float: none;
        top: 0;
        bottom: 0;
        z-index: 155; /* even over gui */
        width: 100%;
        overflow-y: auto;
    }

    .mobile-header #menu,
    .mobile-header #languages {
        max-width: 420px;
        padding: 0px !important;
        margin: 0px !important;
        box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.3);
    }

    .mobile-header #submenu > ul {
        text-align: left;
        left: 0;
        right: 0;
        box-sizing: border-box;
    }

    .mz_rtl.mobile-header #submenu > ul {
        text-align: right;
    }

    .mobile-header #menu,
    .mobile-header #languages {
        border: none;
        transition: 0.2s;
    }

    .mobile-header:not(.mz_rtl) #menu,
    .mobile-header.mz_rtl #languages {
        left: -300px;
    }

    .mobile-header.mz_rtl #menu,
    .mobile-header:not(.mz_rtl) #languages {
        right: -300px;
    }

    .mobile-header:not(.mz_rtl) #menu.visible,
    .mobile-header.mz_rtl #languages.visible
     {
        left: 0px;
    }

    .mobile-header.mz_rtl #menu.visible,
    .mobile-header:not(.mz_rtl) #languages.visible
     {
        right: 0px;
    }

    .mobile-header #menu ul ul {
        padding: 0 !important;
        margin: 0 !important;
        background-color: transparent;
        position: static;
        display: none;
        box-shadow: none;
    }
    
    .mobile-menu-arrow.unfold {
        display: block;
    }
    
    .mobile-menu-arrow.fold {
        display: none;
    }

    .mobile-header #menu > ul,
    .mobile-header #languages ul,
    .mobile-header #submenu > ul {
        margin: 0;
        padding: 15px;
    }
    
    .mobile-header #menu > ul,
    .mobile-header #languages ul {
        display: block !important;
    }

    .mobile-header #menu > ul > li > a,
    .mobile-header #languages ul li > a,
    .mobile-header #submenu > ul > li > a {
        border: none;
        padding: 15px !important;    
    } 

    .mobile-header #menu ul {
        border: none;
        border-radius: 0;
    }

    .mobile-header #menu ul li {
        display: block;
        float: none;
        text-align: left;
        border: none;
        padding: 0;
        margin: 0;
    }
    
    .mobile-header.mz_rtl #menu ul li {
        text-align: right;
    }    

    .mobile-header #menu ul li a,
    .mobile-header #languages ul li > a,
    .mobile-header #submenu ul li a {
        margin: 0px !important;
        display: block !important;
        border-radius: 0 !important;
        text-shadow: none;
        transition: none;
        letter-spacing: normal;
        line-height: 1.3;    
        border: none;
    }

    .mobile-header #menu > ul > li > a:hover, 
    .mobile-header #menu > ul > li.selected > a,
    .mobile-header #menu ul ul li:hover a, 
    .mobile-header #menu ul ul li.selected a,
    .mobile-header #menu ul ul li:hover, 
    .mobile-header #menu ul ul li.selected,
    .mobile-header #languages li.selected > a,
    .mobile-header #languages li > a:hover,
    .mobile-header #submenu ul li a:hover
     {
        border: none;
        background: none;
        box-shadow: none;
        text-shadow: none;
        border-radius: 0;
    }

    .mobile-header #menu ul li:hover > ul {
        animation: none;
        display: none;
    }

    .mobile-header li.expanded .mobile-menu-arrow.unfold {
        display: none;
    }

    .mobile-header li.expanded .mobile-menu-arrow.fold {
        display: block;
    }

    .mobile-header #menu ul li.expanded ul,
    .mobile-header #submenu ul li.expanded ul {
        display: block;
    }

    .mobile-header #menu ul ul li a,
    .mobile-header #submenu ul ul li a {
        padding: 7px 15px 7px 45px !important;
        text-transform: none;
    }

    .mobile-header #menu ul ul li a {
        background: none;
    }

    .mobile-header.mz_rtl #menu ul ul li a { 
        padding-left: 15px !important;
        padding-right: 45px !important;
    }

    .mobile-header #menu ul ul ul li a {
        padding-left: 65px !important;
    }
    
    .mobile-header.mz_rtl #menu ul ul ul li a {
        padding-left: 15px !important;
        padding-right: 65px !important;
    }    

    .mobile-header #menu > ul > li::after {
        content: "";
    }

    .mobile-header #menu > ul > li {
        display: block !important;
    }

    .mobile-header #menu > ul > li > ul {
        padding-bottom: 10px !important;
    }

}



/********************************/
/* Submenu */
/********************************/

body:not(.header-menu-down):not(.header-menu-begin) #submenu,
body.mobile-header #submenu {
    text-align: center;
}

#submenu ul {
    padding-top: 15px;
    padding-bottom: 15px;
}

#submenu a {
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    margin-left: 4px;
    margin-right: 4px;
}

#submenu ul li {
    float: none;
    display: inline;
}

#submenu ul ul {
    display: none;
}

#submenu ul + ul {
    padding-top: 0;
}

#submenu ul + ul a {
    font-size: 0.85em;
}

body:not(.mobile-header) #submenu li.selected ul {
    display: block;
}

#submenu-opener {
    border: 1px solid transparent;
    display: none;
    margin: 20px;
    position: relative;
    cursor: pointer;
}

#submenu-opener span {
    display: block;
    padding: 10px;
    padding-right: 48px;
}

#submenu-opener img,
#submenu-opener svg {
    width: 48px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 750px), screen and (max-height:500px) {

    #submenu ul li {
        display: block;
    }

    #submenu ul li a {
        display: block;
        position: relative;
    }

    #submenu-opener {
        display: inline-block;
    }

}


/********************************/
/* Outer grid and document widths */
/********************************/

.design-width-narrow .container,
.design-width-narrow #header,
.design-width-narrow #bottom,
.design-width-narrow #submenu,
.design-width-narrow #menu-container {
    max-width: 1140px;
}

.design-width-narrow.design-boxed #wrap {
    max-width: 1140px;
}

.section {
    clear: both;
}

.section.section-banner .container {
    padding-bottom: 0px;
}

.container {
    margin: 0 auto;
    padding: 40px;
}

/*.design-boxed .container {
    padding: 32px 40px;
}

.section:first-child .container {
    padding-top: 40px;
}

.section:last-child .container {
    padding-bottom: 40px;
}*/

.section.section-backend-bottom .container {
    padding-top: 0px !important;
    padding-bottom: 40px;
}

/* Height */

.section-height-s {

}

.section-height-m  {
    padding-top: 60px;
    padding-bottom: 60px;
}

.section-height-l {
    padding-top: 160px;
    padding-bottom: 160px;
}

.section-height-xl {
    padding-top: 260px;
    padding-bottom: 260px;
}

.section-height-m .gridrow.section-header {
    padding-bottom: 40px;
}

.section-height-m .gridrow.section-footer {
    padding-top: 40px;
}

.section-height-l .gridrow.section-header {
    padding-bottom: 60px;
}

.section-height-l .gridrow.section-footer {
    padding-top: 60px;
}

.section-height-xl .gridrow.section-header {
    padding-bottom: 80px;
}

.section-height-xl .gridrow.section-footer {
    padding-top: 80px;
}

/* Width */

.section-width-s:not(.section-bigimg) .container {
    max-width: 800px;
}

.section-width-m:not(.section-bigimg) .container {
    max-width: 1000px;
}

.design-boxed .section-width-s.section-bigimg.section-nobox .container {
    max-width: 1100px;
}

.design-boxed .section-width-m.section-bigimg.section-nobox .container {
    max-width: 1100px;
}

.design-boxed .section-width-l.section-bigimg.section-nobox .container {
    max-width: 1200px;
}

.section-width-l .container {

}

@media screen and (max-width: 750px) {

    /* Height */

    .section-height-s {

    }

    .section-height-m {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .section-height-l {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .section-height-xl {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .section-height-m .gridrow.section-header {
        padding-bottom: 25px;
    }

    .section-height-m .gridrow.section-footer {
        padding-top: 25px;
    }

    .section-height-l .gridrow.section-header {
        padding-bottom: 50px;
    }

    .section-height-l .gridrow.section-footer {
        padding-top: 50px;
    }

    .section-height-xl .gridrow.section-header {
        padding-bottom: 75px;
    }

    .section-height-xl .gridrow.section-footer {
        padding-top: 75px;
    }


}

/* Image banner section */

.section-bigimg {
    z-index: 0;
    position: relative;
}

.section-bigimg.section-width-s .textbox {
    max-width: 35%;
}

.section-bigimg.section-width-m .textbox {
    max-width: 50%;
}

.section-bigimg.section-width-l .textbox {
    max-width: 70%;
}


.section-bigimg.section-width-s.section-align-center .textbox {
    max-width: 50%;
}

.section-bigimg.section-width-m.section-align-center .textbox {
    max-width: 70%;
}

.section-bigimg.section-width-l.section-align-center .textbox {
    max-width: 90%;
}


.section-bigimg .container {
    display: flex;
}

.section-align-left .container {
    justify-content: flex-start;
}

.section-align-right .container {
    justify-content: flex-end;
}

.section-align-center .container {
    justify-content: center;
}

.section-bigimg-colorizer {
    display: none;
}



.section-bigimg-container,
.section-bigimg-colorizer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section-bigimg-container {
    background: url('') no-repeat center center;
    background-size: cover;
}

.section-bigimg-fixed {
    background-attachment: fixed;
}

/* ios only */
@supports (-webkit-touch-callout: none) {
   .section-bigimg-fixed {
       background-attachment: initial;
   }
}

.textbox {
    padding: 1.7rem 0;
    box-sizing: border-box;
    min-width: 300px;
}

[class*="section-bg-"].textbox {
    padding: 2.1rem;
}

.section-text-transparent .textbox {
    background-color: transparent;
    padding: 0;
}

.section-text-transparent .section-bigimg-colorizer {
    display: block;
}

.section-align-center .textbox {
    text-align: center
}

@media screen and (max-width: 750px) {

    .section-bigimg.section-width-s .textbox,
    .section-bigimg.section-width-s.section-align-center .textbox {
        max-width: 70%;
    }

    .section-bigimg.section-width-m .textbox,
    .section-bigimg.section-width-l .textbox,
    .section-bigimg.section-width-m.section-align-center .textbox,
    .section-bigimg.section-width-l.section-align-center .textbox {
        max-width: 100%;
    }

    .section-bigimg:not(.section-align-center) {
        padding-bottom: 0;
        padding-top: 290px;
    }

}

@media screen and (max-width: 600px) {

    .section-bigimg.section-width-s .textbox,
    .section-bigimg.section-width-m .textbox,
    .section-bigimg.section-width-l .textbox,
    .section-bigimg.section-width-s.section-align-center .textbox,
    .section-bigimg.section-width-m.section-align-center .textbox,
    .section-bigimg.section-width-l.section-align-center .textbox {
        max-width: 100%;
    }

}

/* Images */

.section-image .mz_component img {
    max-width: 100%;
    width: 100%;
}

/* Video */

figure.mz_inlinevideobox {
    width: 100%;
    position: relative;
    padding-bottom: 56.3%;
    height: 0;
    margin: 0;
}

figure.mz_inlinevideobox iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Section catalog */

.gridrow.center-horizontally {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

/* Extra spacing between image and text when side by side */

@media screen and (min-width: 751px) {

    .gridrow.center-horizontally > div:first-child > div,
    .mz_rtl .gridrow.center-horizontally > div:last-child > div {
        margin-left: 0;
        margin-right: 20px;
    }

    .gridrow.center-horizontally > div:last-child > div,
    .mz_rtl .gridrow.center-horizontally > div:first-child > div {
        margin-left: 20px;
        margin-right: 0;
    }

}

/* Menu section */

.section-menu .gridrow .gridrow [class^="column-"]:last-child {
    text-align: right;
}

.menu-columns-2 {
    column-count: 2;
    column-width: 300px;
    column-gap: 80px;
}

.tablerow {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.tablerow > div {
    display: flex;
    justify-content: space-between;
}

.tablerow:last-child {
    margin-bottom: 0;
}

.tablecell {
    width: calc(90%*0.75);
    break-inside: avoid;
    box-sizing: border-box;
}

.tablecell + .tablecell {
    width: calc(90%*0.25);
    text-align: right;
}

.mz_rtl .tablecell + .tablecell {
    text-align: left;
}

.section-menu-ratio-1-1 .tablecell {
    width: calc(90%*0.5);
}

.section-menu-ratio-1-1 .tablecell + .tablecell {
    width: calc(90%*0.5);
}

.section-menu-ratio-2-1 .tablecell {
    width: calc(90%*0.66);
}

.section-menu-ratio-2-1 .tablecell + .tablecell {
    width: calc(90%*0.33);
}

.section-menu-ratio-3-1 .tablecell {
    width: calc(90%*0.75);
}

.section-menu-ratio-3-1 .tablecell + .tablecell {
    width: calc(90%*0.25);
}

.section-menu-ratio-4-1 .tablecell {
    width: calc(90%*0.80);
}

.section-menu-ratio-4-1 .tablecell + .tablecell {
    width: calc(90%*0.20);
}


/* Colors */

.section-bg-none {
    background-color: #FCF8FD;
}

.section-bg-1 {
    background-color: #F6F7F9;
}

.section-bg-2 {
    background-color: #447CE4;
}

.section-bg-3 {
    background-color: #333333;
}


/*.spacing-ex #bottom,
.spacing-ex #header,
.spacing-ex #bigbar-container,
.spacing-ex #menu-container,
.spacing-ex #submenu > ul {
    padding: 24px 48px;
}

.spacing-ex .container,
.spacing-ex #header,
.spacing-ex #bottom,
.spacing-ex #bigbar-container,
.spacing-ex #menu-container,
.spacing-ex #submenu > ul {
    max-width: 1244px;
}

.spacing-ex .design-boxed #wrap {
    max-width: 1244px;
}

*/

/*.spacing-ex .container {
    margin: 0 auto;
    padding: 24px 40px;
}

.spacing-ex .section:first-child .container {
    padding-top: 40px;
}

.spacing-ex .section:last-child .container {
    padding-bottom: 40px;
}

.spacing-ex .section.section-backend-bottom .container {
    padding-top: 0px !important;
    padding-bottom: 40px;
}

.spacing-ex [class^="column-"] {
    padding: 20px;
}

.spacing-ex .gridrow {
    margin: -20px;
}*/


/********************************/
/* Inner grid */
/********************************/

[class^="column-"] {
    float: left;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
}

[class^="column-"] .mz_wysiwyg {
  overflow-wrap: break-word;
}

.mz_rtl [class^="column-"] {
    float: right;
}

.column-1-12 {
    width: 8.3%;
}

.column-2-12 {
    width: 16.6%;
}

.column-1-4,
.column-3-12 {
    width: 25%;
}

.column-1-3,
.column-4-12 {
    width: 33.3%;
}

.column-5-12 {
    width: 41.6%;
}

.column-1-2,
.column-6-12 {
    width: 50%;
}

.column-7-12 {
    width: 58.3%;
}

.column-2-3,
.column-8-12 {
    width: 66.6%;
}

.column-9-12 {
    width: 75%;
}

.column-10-12 {
    width: 83.3%;
}

.column-11-12 {
    width: 91.6%;
}

.column-1,
.column-12-12 {
    width: 100%;
}

.gridrow {
    clear: both;
    margin: -20px;
}

.gridrow:after, .textbox:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}


/********************************/
/* Sidebar */
/********************************/

.mainblock {
    flex-grow: 1;
    box-sizing: border-box;
    order: 0;
}

.sidebar-reverse .mainblock {
    order: 1;
}

.sidebar-none .mainblock {
    width: 100% !important;
}

.sidebar-none #sidebar {
    display: none;
}

#sidebar-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
}

#sidebar {
    width: 29%;
    min-width: 250px;
    padding: 0 0 0 40px;
    box-sizing: border-box;
    order: 1;
    flex-shrink: 0;
}

.mz_rtl #sidebar-wrap {
    flex-direction: row-reverse;
}

.sidebar-reverse #sidebar {
    padding: 0 40px 0 0;
}

.sidebar-reverse #sidebar {
    order: 0;
}

.design-width-narrow #sidebar {
    width: 31%;
}

#sidebar .sidebox {
    margin-bottom: 25px;
}

#sidebar .sidebox:last-child {
    margin-bottom: 0px;
}


/********************************/
/* Footer at the page bottom */
/********************************/

/* Bottom */

#bottom {
    display: flex;
    flex-wrap: wrap;
    /*align-items: center;*/
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    line-height: 1.6;
}

#footmenu {
    margin-bottom: 1.5em;
}

#footmenu ul li {
    display: inline-block;
    margin: 0.3em 1.5em 0.3em 0;
}

.mz_rtl #footmenu ul li {
    margin: 0.3em 0 0.3em 1.5em;
}

body:not(.mz_rtl) #footmenu ul li:last-child {
    margin-right: 0;
}

.mz_rtl #footmenu ul li:last-child {
    margin-left: 0;
}

#footer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#social {
    margin-top: 1.5em;
}



@media screen and (min-width: 751px) and (min-height:501px) {

    [class*=footer-menu-] #bottom,
    .footer-classic #bottom {
        flex-direction: row;
        text-align: left;
    }

    [class*=footer-menu-].mz_rtl #bottom,
    .footer-classic.mz_rtl #bottom {
        text-align: right;
    }

    /* Classic */

    .footer-classic #footmenu {
        flex: 1 1 100%;
    }

    .footer-classic #footer {
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
    }

    .footer-classic #foottext {
        flex: 1 1 30%;
    }

    .footer-classic #social {
        margin: 0 0 0 2em;
    }

    .mz_rtl.footer-classic #social {
        margin: 0 2em 0 0;
    }

    /* Menu begin */

    .footer-menu-begin #bottom {
        align-items: flex-start;
        justify-content: flex-end; /* in case no menu exists */        
    }

    .footer-menu-begin #footmenu {
        flex: 1 1 63%;
        margin: 0 3% 0 0;
    }

    .mz_rtl.footer-menu-begin #footmenu {
        margin: 0 0 0 3%;
    }

    .footer-menu-begin #footer {
        max-width: 33%;
        align-items: flex-end;
    }

    .footer-menu-begin #footer:first-child

    /* Menu end */

    .footer-menu-end #bottom {
        align-items: flex-start;
    }

    .footer-menu-end #footmenu {
        max-width: 63%;
        margin: 0 0 0 3%;
        order: 1;
    }

    .mz_rtl.footer-menu-end #footmenu {
        margin: 0 3% 0 0;
    }

    .footer-menu-end #footer {
        max-width: 33%;
        align-items: flex-start;
        order: 0;
    }

    .footer-menu-columns #footmenu ul {
        line-height: 1.3;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 100px;
        align-content: flex-start;
    }

    .footer-menu-columns #footmenu ul li {
        display: inline-block;
        margin-right: 60px;
        margin-bottom: 0.5em;
    }

}


/********************************/
/* Social buttons at the bottom */
/********************************/

#social .twitter:hover {
    fill: #44CCF6;
}

#social .facebook:hover {
    fill: #3B5998;
}

#social .pinterest:hover {
    fill: #CB2027;
}

#social .linkedin:hover {
    fill: #0275B6;
}

#social .rss:hover {
    fill: #F88B02;
}

#social .draugiem:hover {
    fill: #E66B19;
}

#social .vk:hover {
    fill: #5A7CA0;
}

#social .instagram:hover {
    fill: #C82D94;
}

#social .youtube:hover {
    fill: #FF0000;
}

#footer .mz_social svg, #footer .mz_social a {
    display: inline-block;
    width: 2.0rem;
    height: 1.7rem;
}

#footer .mz_social a {
    padding: 4px;
}


/********************************/
/* Blog */
/********************************/


.mz_editable .moze-comment {
    margin: 2em 0;
}

.mz_editable .moze-comment div {
    margin-bottom: 5px;
}

.mz_editable .moze-comment div:last-child {
    margin-bottom: 0px;
}

.moze-post {
    margin-bottom: 45px;
}

.moze-post > h1:first-of-type,
.moze-post-container > h1:first-of-type {
    margin-bottom: 0em;
}

.mz_editable .moze-postdetails {
    margin-top: 0.2em;
    margin-bottom: 1.6em;
}

.mz_editable .moze-postdetails a {
    text-decoration: none;
}

.mz_editable .moze-postdetails,
.mz_editable .moze-postdetails a,
.mz_recentposts ul li span {
    color: rgba(0,0,0,0.35);
}

.design-inverted .mz_editable .moze-postdetails,
.design-inverted .mz_editable .moze-postdetails a,
.design-inverted .mz_recentposts ul li span {
    color: rgba(255, 255, 255, 0.5);
}

.mz_editable h2 span.moze-lighter {
    opacity: 0.7;
}

.mz_recentposts a {
    text-decoration: none;
    margin-bottom: 2px;
    display: inline-block;
}

.mz_recentposts ul {
    padding: 0;
    margin-bottom: 1.5em;
}

.mz_recentposts ul li {
    margin-bottom: 0.7em;
    line-height: 1.4;
}

.mz_recentposts ul li span {
    font-size: 0.90em;
}


/********************************/
/* Misc components */
/********************************/

.mz_editable hr.moze-more-divider {
    display: none;
}

.mz_editable .moze-strike {
    text-decoration: line-through;
}

.mz_editable iframe {
    border: none;
}

.moze-social {
    margin-bottom: 25px;
    margin-top: 15px;
}

.moze-social > span {
    vertical-align: middle;
}

.moze-maps, .moze-iframe, .moze-inserted-code, .moze-raw-script {
    max-width: 100%;
}

/* image */

.mz_editable img {
    display: block;
    max-width: 100%;
    box-sizing: border-box;
}

#footer .mz_editable img {
    display: inline-block;
}

/*#title .mz_editable img {
    display: inline-block;
}*/

.mz_editable td img {
    max-width: none;
}

/* buttons */

.moze-button,
.moze-button-large,
.moze-form .moze-formbutton {
    display: inline-block;
    text-decoration: none !important;
    -webkit-appearance: none;
    line-height: 1.5;
    border: none;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
}

.moze-button {
    min-width: 6.5em;
    padding: 7px 14px;
}

.moze-button-large,
.moze-form .moze-formbutton {
    min-width: 9.7em;
    padding: 13px 21px;
}

.moze-form .moze-formbutton {
    margin: 16px 0px 20px 0px;
}

.moze-button.disabled,
.moze-button-large.disabled,
.moze-form .moze-formbutton.disabled {
    opacity: 0.7;
    cursor: default;
    pointer-events:none;
}

/* color: .moze-button-large.btn-white, .moze-button.btn-alt, .moze-button-large.btn-alt  */
/* border-color: .moze-button-large.btn-alt-light  */

.moze-button,
.moze-button-large,
.moze-button:hover,
.moze-button-large:hover,
.mz_editable .moze-button,
.mz_editable .moze-button-large,
.mz_editable .moze-button:hover,
.mz_editable .moze-button-large:hover,
.mz_editable .moze-button:not(.btn-alt) *,
.mz_editable .moze-button-large:not(.btn-alt) * {
    color: #FFFFFF;
    text-decoration: none;
}

.moze-button-large.btn-white,
.moze-button-large.btn-white:hover {
    background-color: #FFFFFF;
    background-image: none;
    border: none;
}

.moze-button-large.btn-white:hover {
    background-color: #F3F3F3;
}

.moze-button-large.btn-alt-white,
.moze-button-large.btn-alt-white:hover {
    border-color: #FFFFFF;
    color: #FFFFFF;
}

.moze-button-large.btn-alt-white:hover {
    opacity: 0.6;
}

.moze-button.btn-alt {
    padding-top: 5px;
    padding-bottom: 5px;
}

.moze-button-large.btn-alt,
.moze-button-large.btn-alt-white,
.moze-button-large.btn-alt-light {
    padding-top: 11px;
    padding-bottom: 11px;
}

.moze-button.btn-alt,
.moze-button-large.btn-alt,
.moze-button-large.btn-alt-white,
.moze-button-large.btn-alt-light {
    border-width: 2px;
    border-style: solid;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
}

.moze-button.btn-alt:hover,
.moze-button-large.btn-alt:hover,
.moze-button-large.btn-alt-white:hover,
.moze-button-large.btn-alt-light:hover {
    background-color: transparent;
    background-image: none;
    box-shadow: none;
}


.mz_inlinebuttons {
    padding-top: 40px;
}

.gridrow.section-footer .mz_inlinebuttons {
    padding-top: 0;
}

/* covers all cases except mentioned below */
.bigbar-overlay-buttons .moze-button-large,
.mz_inlinebuttons .moze-button-large {
    margin-left: 5px;
    margin-right: 5px;
}

/* reset margins for left and right aligned layouts */
.bigbar-overlay-container:not(.overlay-align-center) .moze-button-large,
.section:not(.section-align-center) .mz_inlinebuttons .moze-button-large {
    margin-left: 0px;
    margin-right: 0px;
}

/* if more than 1 button */
.bigbar-overlay-buttons .moze-button-large:not(:last-child),
.mz_inlinebuttons .moze-button-large:not(:last-child) {
    margin-bottom: 10px;
}

/* if more than 1 button and left or right align */
.bigbar-overlay-container:not(.overlay-align-center) .bigbar-overlay-buttons .moze-button-large:not(:last-child),
.section:not(.section-align-center) .mz_inlinebuttons .moze-button-large:not(:last-child) {
    margin-left: 0px;
    margin-right: 10px;
}

.mz_rtl .bigbar-overlay-container:not(.overlay-align-center) .bigbar-overlay-buttons .moze-button-large:not(:last-child),
.mz_rtl .section:not(.section-align-center) .mz_inlinebuttons .moze-button-large:not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
}

@media screen and (max-width: 750px) {
    .moze-button-large, .moze-form .moze-formbutton {
        min-width: 120px;
    }
}

/* fixes issue with google maps */

.mz_editable .moze-maps img {
    max-width: none;
    border: none;
    box-shadow: none;
}

/*.mz_editable div.ui-wrapper:first-child,*/
.mz_editable .moze-wysiwyg-editor > img:first-child {
    margin-top: 0 !important;
}

/*.mz_editable div.ui-wrapper:last-child,*/
.mz_editable .moze-wysiwyg-editor > img:last-child {
    margin-bottom: 0 !important;
}

.mz_editable .moze-img-right {
    float: right;
    margin: 20px 0px 20px 20px !important;
}

.mz_editable .moze-img-left {
    float: left;
    margin: 20px 20px 20px 0px !important;
}

.mz_editable .moze-img-center {
    display: block;
    margin: 20px auto !important;
}

/* always clear editable box */
.mz_editable div.moze-wysiwyg-editor::after {
    content: " ";
    clear: both;
    display: table;
}

@media screen and (max-width: 750px) {
    .mz_editable img, .mz_editable img.moze-img-right, .mz_editable img.moze-img-left {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.mz_recentposts ul li {
    list-style: none;
}



/********************************/
/* Form */
/********************************/

.moze-form {
    margin: 19px 0px;
}

.moze-form input[type="text"],
.moze-form input[type="email"],
.moze-form select {
    width: 100%;
    min-width: 250px;
    /*max-width: 350px;*/
    box-sizing: border-box;
}

.moze-form textarea {
    width: 100%;
    min-width: 250px;
    /*max-width: 700px;*/
    height: 100px;
    box-sizing: border-box;
}

.moze-form label {
    display: inline-block;
    margin: 0px 0px 4px 0px;
}

.moze-form input:not([type="checkbox"]),
.moze-form select,
.moze-form textarea {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
}

.moze-form .moze-checkbox {
    line-height: 1.5em;
    margin-bottom: 20px;
}

.mz_form.comment textarea {
    height: 130px;
}

.mz_form span {
    vertical-align: top;
}

@media screen and (max-width: 750px) {
    .moze-form input[type="text"],
    .moze-form input[type="email"],
    .moze-form select,
    .moze-form textarea  {
        width: 100%;
        max-width: none;
    }

}

.moze-form .moze-formerror {
    box-shadow: 0 0 0px 2px hsla(5, 100%, 58%, 0.7);
}


/********************************/
/* Checkout form */
/********************************/


#cat-order.moze-form input[type="text"],
#cat-order.moze-form input[type="email"],
#cat-order.moze-form select,
#cat-order.moze-form textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 3.2em;
    max-width: none;
    min-width: auto;
    margin-bottom: 18px;
}

#cat-order.moze-form label.check input[type="checkbox"] {
    margin: 3px 0;
    position: absolute;
    left: 0;
}

.mz_rtl #cat-order.moze-form label.check input[type="checkbox"] {
    left: auto;
    right: 0;
}

#cat-order.moze-form label.check {
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    padding-left: 20px;
}

.mz_rtl #cat-order.moze-form label.check {
    padding-left: 0;
    padding-right: 20px;
}

#cat-order.moze-form textarea {
    height: auto;
}

#cat-discount-success {
    visibility: hidden;
    display: inline-block;
    background: url(../../../libs/icons/icon-check-green.svg) no-repeat center center;
    width: 16px;
    background-size: cover;
    height: 16px;
    vertical-align: middle;
    margin: 0 5px;
}

#cat-discount.moze-form input {
    font-size: 1rem;
    padding: 0.6rem;
    margin: 0;
    box-sizing: border-box;
    width: 200px;
}

#cat-discount.moze-form {
    margin-top: 15px;
    position: relative;
}

#cat-discount.moze-form .moze-formbutton {
    width: auto;
    margin-left: 5px;
    cursor: pointer;
}

.mz_rtl #cat-discount.moze-form .moze-formbutton {
    margin-left: 0px;
    margin-right: 5px;
}

#cat-order.moze-form label {
    /*white-space: nowrap;
    text-overflow: ellipsis;*/
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.3;
}

label[for="std_addr_zip"] {
    white-space: nowrap;
    text-overflow: ellipsis;
}

#cat-order.moze-form h3 {
    margin-bottom: 1em;
}

#cat-loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: url(misc/spinner.gif) no-repeat #FFFFFF center center;
    display: none;
}

/*#cat-loading-screen.small {
    margin: auto auto;
    width: 120px;
    height: 120px;
    border-radius: 120px;
}*/

#cat-loading-screen div {
    bottom: 50%;
    position: absolute;
    padding-bottom: 80px;
    text-align: center;
    width: 100%;
}


/********************************/
/* Catalog */
/********************************/


/* catalog - cart */

.mz_catalogcart td {
    vertical-align: middle;
    padding: 10px 7px;
}

.mz_catalogcart td.name {
    width: 100%;
}

.mz_catalogcart td.qty {
    padding: 3px 0px;
    width: 5%;
}

.mz_catalogcart td.qty a {
    background: #EEEEEE url(icons/icon-delete-gray.svg) no-repeat center center;
    background-size: 10px;
    display: block;
    border-radius: 15px;
    width: 20px;
    height: 20px;
    padding-top: 0;
}

.mz_catalogcart td.qty div.delete {
    position: absolute;
    box-sizing: border-box;
    width: 20px;
    height: 100%;
    opacity: 1;
    right: -25px;
    display: flex;
    align-items: center;
}

.mz_rtl .mz_catalogcart td.qty div.delete {
    left: -25px;
    right: auto;
}

/*.mz_catalogcart .moze-form input[type="text"]:focus + div.delete,
.mobile-header .mz_catalogcart td.qty div.delete,
.mz_catalogcart tr:hover td.qty div.delete {
    opacity: 1;
}*/

.mz_catalogcart div.relative {
    position: relative;
    float: left;
}

.mz_rtl .mz_catalogcart div.relative {
    float: right;
}

.mz_catalogcart td.pic {
    width: 48px;
    padding: 0 6px 0 0;
}

.mz_rtl .mz_catalogcart td.pic {
    padding: 0 0 0 6px;
}

/*.mz_catalogcart td.cost {
    text-align: right;
    padding: 3px 0px;
    font-weight: bold;
}*/

.mz_catalogcart td span.cost {
    font-weight: bold;
}

/*.mz_catalogcart td.cost, */
.mz_catalogcart div.totalcost {
    white-space: nowrap;
}

/*.mz_rtl .mz_catalogcart td.cost {
    text-align: left;
}*/

.mz_catalogcart .summary {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.mz_catalogcart div.total {
    padding: 17px 10px 0px 0px;
}

.mz_rtl .mz_catalogcart div.total {
    padding: 17px 0px 0px 10px;
}

.mz_catalogcart div.totalcost {
    padding: 17px 0px 0px 0px;
    font-weight: bold;
    text-align: right;
}

.mz_rtl .mz_catalogcart div.totalcost {
    text-align: left;
}

.mz_catalogcart .moze-button-large {
    text-align: center;
    display: block !important;
}

.mz_catalogcart .moze-form input[type="text"] {
    width: 1.6em;
    padding: 5px 2px;
    margin-bottom: 0px;
    display: block;
    float: left;
    text-align: center;
    min-width: auto;
    max-width: none;
}

.mz_rtl .mz_catalogcart .moze-form input[type="text"] {
    float: right;
}

.mz_eurocalc .moze-form input[type="text"] {
   width: 20%;
   padding: 5px 2px;
   text-align: center;
   margin-bottom: 0px;
}

.mz_catalogcart .moze-form,
.mz_eurocalc .moze-form {
    padding: 0px;
    border: none;
    box-shadow: none;
    background: none;
}

.mz_catalogcart table {
    width: 100%;
}

/* catalog - cats */

.mz_catalogcategories ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.3;
}

.mz_catalogcategories ul li a {
    margin-bottom: 0.5em;
    display: inline-block;
}

.mz_catalogcategories ul li ul li {
    margin-left: 1.5em;
    font-size: 0.95em;
}

.mz_catalogcategories ul li.selected {
    font-weight: 600;
}

/* catalog - general */

.mz_catalog .cat-thumb-pic {
    margin: 0.75em 0;
    padding-top: 80%;
    /*background: url(/backend/css/cat-default-product.png) no-repeat center center;
    background-size: contain;*/
    position: relative;
    overflow: hidden;
}

.mz_catalog .cat-thumb-pic img {
    max-height: 100%;
    max-width: 100%;
    min-width: 1px;
    min-height: 1px;
    width: auto;
    height: auto;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    transition: transform 0.3s;
}

.mz_catalog .cat-thumb-pic img:hover {
    transform: scale(1.05);
}

.mz_catalog.cat-images-square .cat-thumb-pic img,
.mz_catalog.cat-images-wide .cat-thumb-pic img,
.mz_catalog.cat-images-tall .cat-thumb-pic img {
    max-width: none;
    max-height: none;
}


.mz_catalog.cat-images-square .cat-thumb-pic,
.mz_catalog.cat-images-square .cat-more-item-images a.thumb {
    padding-top: 100%;
}

.mz_catalog.cat-images-square .cat-thumb-pic img {
    height: 100%;
}

.mz_catalog.cat-images-square .cat-thumb-pic img.portrait {
    height: auto;
    width: 100%;
}


.mz_catalog.cat-images-wide .cat-thumb-pic,
.mz_catalog.cat-images-wide .cat-more-item-images a.thumb {
    padding-top: 66%;
}

.mz_catalog.cat-images-wide .cat-thumb-pic img {
    width: 100%;
}

.mz_catalog.cat-images-wide .cat-thumb-pic img.wider {
    width: auto;
    height: 100%;
}


.mz_catalog.cat-images-tall .cat-thumb-pic,
.mz_catalog.cat-images-tall .cat-more-item-images a.thumb {
    padding-top: 150%;
}

.mz_catalog.cat-images-tall .cat-thumb-pic img {
    height: 100%;
}

.mz_catalog.cat-images-tall .cat-thumb-pic img.taller {
    height: auto;
    width: 100%;
}

.cat-grid {
    margin: -1.5%;
    display: flex;
    flex-wrap: wrap;
}

.cat-grid:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.cat-message {
    padding: 2em 1.5%;
    width: 100%;
}

/* 3 images - default */

.mz_catalog .cat-thumb {
    position: relative;
    width: 33.3%;
    box-sizing: border-box;
    padding: 1.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mz_catalog.cat-images-1 .cat-thumb {
    width: 100%;
    padding: 1.5%;

}


/* 2 images */

@media screen and (min-width: 481px) {

    .mz_catalog.cat-images-2 .cat-thumb {
        width: 50%;
        padding: 1.5%;
    }

}


/* 4 images */

@media screen and (min-width: 901px) {

    .mz_catalog.cat-images-4 .cat-thumb {
        width: 25%;
        padding: 1.5%;
    }

}


@media screen and (max-width: 900px) {

    .mz_catalog .cat-thumb {
        width: 33.3%;
        padding: 3%;
    }

}

@media screen and (max-width: 750px) {

    .mz_catalog .cat-thumb {
        width: 50%;
        padding: 3%;
    }

}

@media screen and (max-width: 480px) {

    .mz_catalog .cat-thumb {
        width: 100%;
        padding: 3%;
    }

}

.mz_catalog .cat-thumb-title {
    display: block;
    margin: 15px 15px 0px 15px;
    text-align: center;
}

.mz_catalog .cat-thumb-price {
    margin: 2px 15px 15px 15px;
    text-align: center;
}

.mz_catalog .moze-price {
    /*color: #CC0000;*/
    font-weight: 600;
}

.mz_catalog .cat-item-inventory {
    margin-bottom: 12px;
}

.mz_catalog .cat-item-inventory:before {
    content:"\A";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #4CB963;
    display: inline-block;
    margin-right: 0.5em;
}

.mz_rtl .mz_catalog .cat-item-inventory:before {
    margin-right: 0;
    margin-left: 0.5em;
}

.mz_catalog .cat-item-inventory.inventory-in-stock:before,
.mz_catalog .cat-item-inventory.inventory-in-stock-x:before {
    background: #4CB963;

}
.mz_catalog .cat-item-inventory.inventory-can-order:before {
    background: #F5C744;

}
.mz_catalog .cat-item-inventory.inventory-out-of-stock:before {
    background: #D7263D;
}

.mz_catalog .cat-item-buy .moze-price  {
    font-size: 1.35em;
}

.mz_catalog .cat-item-buy .moze-form  {
    margin: 0;
}

.mz_catalog .cat-item-buy s,
.mz_catalog .cat-item-buy .moze-price,
.mz_catalog .cat-item-buy .moze-button  {
    vertical-align: middle;
}

/*.mz_catalog .cat-item-text h1 {
    margin-bottom: 0.4em;
}*/

.cat-item-price {
    margin-bottom: 30px;
}

.cat-item-buy .moze-cart-form select:last-of-type {
    margin-bottom: 35px;
}

.mz_catalog .cat-item-text .cat-item-buy {
    /*margin: 25px 0 45px 0;*/
    margin: 0 0 45px 0;
}

.cat-item-buy .moze-button-large {
    width: 100%;
}

.moze-form .cat-item-variants {
    width: 100%;
    min-width: auto;
}

@media screen and (min-width: 751px) {

    .mz_catalog.cat-legacy-view .cat-item-images {
        width: 36%;
        float: right;
        margin: 0 0 0 4%;
    }

    .is-rtl .mz_catalog.cat-legacy-view .cat-item-images {
        float: left;
        margin: 0 4% 0 0;
    }

    .cat-item-buy .moze-button-large {
        width: auto;
        min-width: 250px;
    }

    .moze-form .cat-item-variants {
        width: auto;
        max-width: none;
        min-width: auto;
    }

    .mz_catalog.cat-image-right .cat-item-view,
    .mz_catalog.cat-image-left .cat-item-view {
        display: flex;
        justify-content: space-between;
    }

    .mz_catalog.cat-image-right .cat-item-images {
        width: 48%;
        margin: 0 0 0 6%;
        order: 1;
    }

    .mz_catalog.cat-image-left .cat-item-images {
        width: 48%;
        margin: 0 6% 0 0;
        order: 0;
    }

    .mz_catalog.cat-image-right .cat-item-text {
        width: 46%;
        order: 0;
    }

    .mz_catalog.cat-image-left .cat-item-text {
        width: 46%;
        order: 1;
    }

    .mz_catalog.cat-image-right .cat-item-images > a > img,
    .mz_catalog.cat-image-left .cat-item-images > a > img {
        max-height: none;
    }

}

.mz_catalog .cat-item-images > a > img {
    margin: 0px auto;
    max-height: 700px;
}

.mz_catalog .cat-more-item-images a.thumb {
    display: block;
    padding-top: 89%;
    background: none no-repeat center center;
    background-size: contain;
    border: none;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

.mz_catalog .cat-more-item-images {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
}

.mz_catalog .cat-more-item-images .cat-thumb-pic {
    margin: 0;
    padding: 10px;
    width: 50%;
    box-sizing: border-box;
}

.mz_catalog.cat-image-left .cat-more-item-images .cat-thumb-pic,
.mz_catalog.cat-image-right .cat-more-item-images .cat-thumb-pic {
    width: 33.3%;
}

.mz_catalog.cat-buttons-none .cat-thumb-button {
    display: none;
}
.mz_catalog .cat-thumb-button {
    display: block;
    text-align: center;
}
.mz_catalog .cat-thumb-button a {
    margin-top: 0.7rem;
    margin-bottom: 1.4rem;
    min-width: 150px;
}


/* catalog - cart */

.mz_catalog #cat-cart {
    width: 100%;
}

.mz_catalog #cat-cart td.count,
.mz_catalog #cat-cart td.cost,
.mz_catalog #cat-cart td.discounted,
.mz_catalog #cat-cart td.total
{
    text-align: right;
    width: 14%;
}

.mz_rtl .mz_catalog #cat-cart td.count,
.mz_rtl .mz_catalog #cat-cart td.cost,
.mz_rtl .mz_catalog #cat-cart td.discounted,
.mz_rtl .mz_catalog #cat-cart td.total
{
    text-align: left;
}

.mz_catalog #cat-cart td.cost,
.mz_catalog #cat-cart td.totalcost,
.mz_catalog #cat-cart td.discounted,
.mz_catalog #cat-cart td.total
{
    white-space: nowrap;
}

.mz_catalog #cat-cart td.totalcost
{
    text-align: right;
    font-weight: bold;
}

.mz_rtl .mz_catalog #cat-cart td.totalcost
{
    text-align: left;
}

td div.tinypic {
    height: 48px;
    width: 48px;
    /*background: url(../../../backend/css/cat-default-product.svg) no-repeat center center;*/
    background-size: contain;
}

.mz_catalog #cat-cart div.tinypic {
    height: 64px;
    width: 64px;
    min-width: 64px;
}

.mz_catalog #cat-cart td.name > div {
    display: flex;
    align-items: center;
}

#cat-cart div.tinypic
{
    margin: 0 1em 0 0;
}

.mz_rtl #cat-cart div.tinypic
{
    margin: 0 0 0 1em;
}

.mz_catalogcart.sidebox:empty {
    display: none;
}

.moze-cart-form td, .moze-cart-form input[type="text"]
{
    font-size: 0.95em;
}

@media screen and (max-width: 900px) {
    #sidebar .mz_catalogcart td.cost,
    #sidebar .mz_catalogcart td.totalcost,
    .mz_catalog #cat-cart td.cost,
    .mz_catalog #cat-cart td.totalcost,
    .mz_catalog #cat-cart td.discounted,
    .mz_catalog #cat-cart td.total
    {
        white-space: normal;
    }
}

@media screen and (max-width: 750px) {

    .mz_catalog .cat-item-images {
        width: auto;
        margin-left: 0;
    }
    .mz_catalog .cat-item-text {
        clear: both;
    }
    .mz_catalog .cat-item-images > a > img {
        max-height: 900px;
    }

    .mz_catalog .cat-more-item-images .cat-thumb-pic {
        width: 33.3%;
    }

}

#cat-order .cat-order-half {
    width: 50%;
    float: left;
    box-sizing: border-box;

}

#cat-order .row-container {
    margin: 0 -8px;
}

#cat-order .row {
    padding-left: 8px;
    padding-right: 8px;
}

#cat-order .payment-logos {
    margin-top: 26px;
}

#cat-order .payment-logos img {
    max-width: 55px;
    display: inline;
    margin: 3px;
}

.mz_rtl #cat-order .cat-order-half {
    float: right;
}

@media screen and (max-width: 750px) {

    #cat-order .cat-order-half {
        width: auto !important;
        float: none;
        padding: 0px;
    }
    #cat-order br.special {
        display: none;
    }
}

/* catalog - sort navigation */

.cat-breadcrumb {
    margin: 0;
    padding: 0;
    flex-grow: 1;
}

.cat-breadcrumb li {
    padding: 0 1.2em 0 0;
    margin: 0 0.6em 0 0;
    display: inline-block;
    position: relative;
}

.cat-breadcrumb li:not(:last-child):after {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: absolute;
    content: "/";
    right: 0;
    opacity: 0.3;
}

#cat-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2em;
    flex-wrap: wrap;
}

#cat-nav .moze-form {
    display: inline-block;
    margin: 0;
}

.cat-sort {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.cat-sort select {
    width: auto;
    min-width: auto;
    margin: 0;
}

.cat-sort label {
    margin: 0 1em;
}

.cat-filter-toggle {
    flex-shrink: 0;
    cursor: pointer;
}

.cat-filter-toggle div {
    display: inline-block;
    vertical-align: middle;
}

.cat-filter-toggle div.counter {
    font-weight: bolder;
}

.cat-filter-toggle svg,
.cat-filter-toggle img {
    width: 48px;
    height: 48px;
    padding: 11px;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
}

#cat-filter .moze-form label {
    padding: 0.4em 0;
    margin: 0;
    width: 100%;
    cursor: pointer;
}

#cat-filter .moze-form .moze-checkbox input {
    margin-right: 1em;
}

.mz_rtl #cat-filter .moze-form .moze-checkbox input {
    margin-right: auto;
    margin-left: 1em;
}

.cat-filter-group {
    margin: 1.5em 0;
}

.cat-filter-group h3 {
    margin-bottom: 0.5em;
}

@media screen and (max-width: 750px) {

    #cat-nav {
        justify-content: center;
    }

    .cat-breadcrumb {
        display: none;
    }

}

/* catalog - searchbox */

.mz_catalogsearchbox input.search-query {
    box-sizing: border-box;
    width: 100%;
    padding-right: 35px;
    background-image: url('../../../libs/icons/icon-find-grey.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
    background-size: 20px 20px;
    min-width: auto;
    margin-bottom: 0;
}
.mz_catalogsearchbox div.search-btn {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    top: 1px;
    right: 1px;
}

.moze-catalog-searchbox-form {
    border: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    background: none !important;
    margin-bottom: 0;
}

/* catalog - side widget for cart */

#shopbar {
    display: none;
    position: fixed;
    right: 0;
    top: 25%;
}

#shopbar button {
    color: inherit;
}

#shopbar img,
#shopbar svg,
.sliding-panel-close img,
.sliding-panel-close svg {
    width: 48px;
    padding: 11px;
    display: block;
    box-sizing: border-box;
    transition-duration: 0.2s;
}

#shopbar-search,
#shopbar-cart {
    text-align: center;
    position: relative;
    cursor: pointer;
    display: block;
    padding: 0;
    background: none;
    border: none;
}

#shopbar-search:hover svg,
#shopbar-cart:hover svg,
.sliding-panel-close:hover svg {
    transform: scale(1.2);
}

#shopbar-sidecart-base > div.mz_editable {
    display: none;
}

#shopbar .separator {
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 0;
    height: 1px;
    opacity: 0.3;
}

#shopbar-cart span {
    padding: 0 5px 10px 5px;
    display: block;
}

body:not(.mz_rtl) #shopbar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.mz_rtl  #shopbar {
    right: auto;
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#shopbar-search .close {
    display: none;
}

#shopbar-searchform {
    display: none;
    position: absolute;
    right: 0;
    padding: 7px;
    padding-right: 48px;
}

.mz_rtl #shopbar-searchform {
    right: auto;
    left: 0;
    padding-right: 7px;
    padding-left: 48px;
}

#shopbar-searchform input,
#shopbar-searchform .search-btn {
    width: 300px;
    padding: 5px;
    box-sizing: border-box;
    height: 34px;
    line-height: 34px;
}

#shopbar-searchform .search-btn {
    position: absolute;
    top: 0;
    right: 48px;
    height: 48px;
    width: 48px;
    background: url('../../../libs/icons/icon-find-grey.svg') no-repeat center center;
    background-size: 50%;
    cursor: pointer;

    display: block;
    border: none;
    padding: 0;
}

.mz_rtl  #shopbar-searchform .search-btn {
    right: auto;
    left: 48px;
}

@media screen and (max-width: 1300px) {
    #shopbar img,
    #shopbar svg {
        padding: 7px;
        width: 34px;
    }
    #shopbar-searchform {
        padding-right: 34px;
    }
    .mz_rtl #shopbar-searchform {
        padding-right: 7px;
        padding-left: 34px;
    }
    #shopbar-searchform .search-btn {
        right: 34px;
    }
    .mz_rtl #shopbar-searchform .search-btn {
        left: 34px;
        right: auto;
    }

}

@media screen and (max-width: 750px) {
    body {
        margin-bottom: 60px;
    }
    #shopbar, .mz_rtl  #shopbar {
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 54;
        border-radius: 0 !important;
    }
    #shopbar-search, #shopbar-cart {
        width: auto;
        float: left;
        z-index: 51;
    }
    #shopbar-search {
        float: right;
        z-index: 52;
    }
    #shopbar img,
    #shopbar svg {
        float: left;
        padding: 12px;
        width: 48px;
    }
    #shopbar .separator {
        display: none;
        /*left: auto;
        right: 0;
        top: 20%;
        bottom: 20%;
        height: auto;
        width: 1px;*/
    }
    #shopbar-cart span {
        float: left;
        padding: 0;
        line-height: 48px;
    }
    #shopbar-searchform {
        left: 0;
        right: 0;
        padding: 7px;
        padding-right: 48px;
        z-index: 52;
    }
    #shopbar-searchform input {
        width: 100%;
        margin-right: 0px;
    }
    #shopbar-searchform .search-btn {
        right: 48px;
    }

}

/* catalog - side shopping cart & filter */

.sliding-panel {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 155;  /* even over gui */
    box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.3);
    transition: 0.2s;
    box-sizing: border-box;
}

.sliding-panel-header {
    padding: 30px 40px;
}

.sliding-panel-body {
    position: absolute;
    top: 90px;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    padding: 0 40px 40px 40px;
    overflow-y: auto;
}

.sliding-panel-header h2 {
    margin: 0;
    font-size: 1.5rem !important;
}

.sliding-panel {
    right: -400px;
    left: auto;
}

.mz_rtl .sliding-panel {
    right: auto;
    left: -400px;
}

body:not(.mz_rtl) .sliding-panel.visible {
    right: 0px;
}

.mz_rtl .sliding-panel.visible {
    left: 0px;
}

.sliding-panel form{
    margin: 0;
}

.sliding-panel-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    display: block;
    border: none;
    background: transparent;
    padding: 0;
}

.mz_rtl .sliding-panel-close {
    right: auto;
    left: 20px;
}

/* Scrollbars for Chromium */

/* Looks and works differently in FireFox */
.sliding-panel-body {
    /*scrollbar-color: #66717F transparent;*/
    scrollbar-width: thin;
}

.sliding-panel-body::-webkit-scrollbar {
    width: 10px;
}
.sliding-panel-body::-webkit-scrollbar-track {
    background: transparent;
}
.sliding-panel-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.sliding-panel-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.15);
}
.design-inverted .sliding-panel-body::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
}
.design-inverted .sliding-panel-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.15);
}


#cookie-notification {
    z-index: 55 !important;
}


/********************************/
/* Gallery */
/********************************/

ul.mz_gallery {
    width: 100%;
}

ul.moze-gallery a,
ul.moze-gallery a:hover {
    text-decoration: none;
    background-color: rgba(0,0,0,0); /* fixes IE bug when transparent elements are not clickable */
}

ul.moze-gallery {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.moze-gallery li {
    background-color: #222222;
    float: left;
    list-style-type: none !important;
    position: relative;
}

.mz_rtl ul.moze-gallery li {
    float: right;
}

ul.moze-gallery li .moze-gallery-overlay {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

ul.moze-gallery li:hover .moze-gallery-overlay {
    opacity: 1;
}

ul.moze-gallery li:hover img,
ul.moze-gallery li:hover .moze-gallery-group-icon {
    opacity: 0.35;
}

ul.moze-gallery li div.title {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

ul.moze-gallery li div.title span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
}

body.backend ul.moze-gallery.pictures li div.title span {
    cursor: default;
}

ul.moze-gallery li img {
    display: block;
    position: absolute;
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Default - max 4 images side by side */

ul.moze-gallery li,
ul.moze-gallery li:nth-child(n),
ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
    width: 23%;
    margin: 0 2.6% 2.6% 0;
    padding-bottom: 23%;
}

ul.moze-gallery li:nth-child(4n),
ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(4n+1) {
    margin-right: 0;
}

.mz_rtl ul.moze-gallery li,
.mz_rtl ul.moze-gallery li:nth-child(n),
.mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
    margin: 0 0 2.6% 2.6%;
}

.mz_rtl ul.moze-gallery li:nth-child(4n),
.mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(4n+1) {
    margin-left: 0;
}

/* Max 3 images side by side at this resolution */

@media screen and (max-width: 600px) {

    ul.moze-gallery li,
    ul.moze-gallery li:nth-child(n),
    ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        width: 31%;
        margin: 0 3.4% 3.4% 0;
        padding-bottom: 31%;
    }

    ul.moze-gallery li:nth-child(3n),
    ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(3n+1) {
        margin-right: 0;
    }

    .mz_rtl ul.moze-gallery li,
    .mz_rtl ul.moze-gallery li:nth-child(n),
    .mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        margin: 0 0 3.4% 3.4%;
    }

    .mz_rtl ul.moze-gallery li:nth-child(3n),
    .mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(3n+1) {
        margin-left: 0;
    }

}

/* Max 2 images side by side at this resolution */

@media screen and (max-width: 480px) {

    ul.moze-gallery li,
    ul.moze-gallery li:nth-child(n),
    ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        width: 48%;
        margin: 0 3.8% 3.8% 0;
        padding-bottom: 48%;
    }

    ul.moze-gallery li:nth-child(2n),
    ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(2n+1) {
        margin-right: 0;
    }

    .mz_rtl ul.moze-gallery li,
    .mz_rtl ul.moze-gallery li:nth-child(n),
    .mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        margin: 0 0 3.8% 3.8%;
    }

    .mz_rtl ul.moze-gallery li:nth-child(2n),
    .mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(2n+1) {
        margin-left: 0;
    }

}

/* Max 3 images side by side */

@media screen and (min-width: 600px) {

    .gallery-max-3 ul.moze-gallery li,
    .gallery-max-3 ul.moze-gallery li:nth-child(n),
    .gallery-max-3 ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        width: 31%;
        margin: 0 3.4% 3.4% 0;
        padding-bottom: 31%;
    }

    .gallery-max-3 ul.moze-gallery li:nth-child(3n),
    .gallery-max-3 ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(3n+1) {
        margin-right: 0;
    }

    .gallery-max-3.mz_rtl ul.moze-gallery li,
    .gallery-max-3.mz_rtl ul.moze-gallery li:nth-child(n),
    .gallery-max-3.mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        margin: 0 0 3.4% 3.4%;
    }

    .gallery-max-3.mz_rtl ul.moze-gallery li:nth-child(3n),
    .gallery-max-3.mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(3n+1) {
        margin-left: 0;
    }

}

/* Max 5 images side by side */

@media screen and (min-width: 700px) {

    .gallery-max-5 ul.moze-gallery li,
    .gallery-max-5 ul.moze-gallery li:nth-child(n),
    .gallery-5 ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        width: 18%;
        margin: 0 2.45% 2.45% 0;
        padding-bottom: 18%;
    }

    .gallery-max-5 ul.moze-gallery li:nth-child(5n),
    .gallery-max-5 ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(5n+1)   {
        margin-right: 0;
    }

    .gallery-max-5.mz_rtl ul.moze-gallery li,
    .gallery-max-5.mz_rtl ul.moze-gallery li:nth-child(n),
    .gallery-max-5.mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(n) {
        margin: 0 0 2.45% 2.45%;
    }

    .gallery-max-5.mz_rtl ul.moze-gallery li:nth-child(5n),
    .gallery-max-5.mz_rtl ul.moze-gallery li.ui-sortable-helper ~ li:nth-child(5n+1)   {
        margin-left: 0;
    }

}



/********************************/
/* Table */
/********************************/

.mz_editable table td {
    min-width: 20px;
}



/********************************/
/* Small screen */
/********************************/

@media screen and (max-width: 750px), screen and (max-height:500px) {

    #title {
        float: none;
        text-align: center;
        margin: 0px !important;
        padding: 0px !important;
    }

    #title .mz_wysiwyg, .mz_rtl #title .mz_wysiwyg {
        margin: 0px 0px 25px 0px !important;
        word-wrap: break-word;
    }

    .mobile-header #title .mz_wysiwyg img:not([src$=".svg"]):not([src*=".svg?"]) {
        width: auto !important;
    }

    .mobile-header #title .mz_wysiwyg img {
        max-height: 32px;
        max-width: 240px;
    }

    .mobile-header #title .mz_wysiwyg img.tall {
        max-height: 72px;
    }

    .mobile-header #title .mz_wysiwyg,
    .mobile-header.mz_rtl #title .mz_wysiwyg {
        margin: 0 !important;
    }

    .mz_rtl #title {
        float: none;
    }

    #title .mz_wysiwyg {
        display: inline-block;
        /*width: 100%;*/
    }

    #toptext,
    .mz_rtl #toptext {
        float: none;
        margin: 0 !important;
    }

    #toptext .mz_wysiwyg,
    .mz_rtl #toptext .mz_wysiwyg {
        display: block;
        text-align: center;
        margin: 0px 0 25px 0 !important;
    }

    .mobile-header #toptext .mz_wysiwyg,
    .mobile-header.mz_rtl #toptext .mz_wysiwyg {
        margin: 15px 0 0 0 !important;
    }

    #toptext .mz_wysiwyg .moze-wysiwyg-editor,
    .mz_rtl #toptext .mz_wysiwyg .moze-wysiwyg-editor {
        display: block;
        height: auto;
    }

    #menu {
        margin-top: 0px !important;
    }

    #menu-container {
        padding: 0px 20px;
    }

    .mobile-header #header {
        padding: 5px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mobile-header #header.floating {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        z-index: 51;
        transition: background-color 0.5s ease;
    }

    .mobile-header #header.floating #toptext {
        display: none;
    }

    .mobile-header #header-main {
        order: 1;
        flex: 1 1 auto;
        min-height: auto;
    }

    .mobile-header #title {
        order: 1;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media screen and (max-width: 750px) {

    html {
        font-size: 14px;
    }

    #header {
        position: relative;
    }

    #bottom, #header {
        padding: 20px 20px !important;
    }

    #bigbar-container {
        padding: 0 20px;
    }

    .container, .design-boxed .container {
        padding: 20px;
    }

    .section:first-child .container {
        padding-top: 20px;
    }

    .section:last-child .container {
        padding-bottom: 20px;
    }

    .section.section-backend-bottom .container {
        padding-top: 0px !important;
        padding-bottom: 20px;
    }

    [class^="column-"] {
        padding: 20px 10px;
    }

    .gridrow {
        margin: -20px -10px;
    }

    #sidebar-wrap {
        position: static;
        display: block;
    }

    #submenu {
        /*display: block;*/
    }

    [class^="column-"] {
        width: 100%;
    }

    .mainblock, .mz_rtl .mainblock {
        float: none;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    #sidebar, .sidebar-reverse #sidebar {
        padding: 25px 0 0 0;
        float: none;
        clear: both;
        margin-left: 0px;
        margin-right: 0px;
        width: auto !important;
        position: static;
    }

    #sidebar .sidebox {
        margin: 0px 0px 25px 0px;
    }

}

@media screen and (max-width: 600px) {

    html {
        font-size: 13px;
    }

}

@media screen and (max-width: 420px) {

    #title .mz_wysiwyg img {
        max-width: 220px;
    }

}

@media screen and (max-width: 370px) {

    #title .mz_wysiwyg img {
        max-width: 100%;
    }

}



/********************************/
/* Animations */
/********************************/

#languages ul li a,
.moze-button,
.moze-button-large,
.moze-button *,
.moze-button-large *,
.moze-form .moze-formbutton,
.mz_editable .moze-gallery .moze-gallery-overlay,
#social a {
    transition-property: color, background-color, opacity, border-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
}

#top.sticky #menu {
    transition: background-color 0.3s ease;
}

#menu-container {
    margin: 0 auto;
}

#menu ul li a {
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

#mz_customizer[style*="block"] ~ #top.sticky #menu ul li a,
#mz_customizer[style*="block"] ~ #top.sticky #menu,
#mz_customizer[style*="block"] ~ div #top.sticky #menu ul li a,
#mz_customizer[style*="block"] ~ div #top.sticky #menu  {
    transition: none;
}

#menu ul li:hover > ul {
    animation: fadein 0.3s;
    -webkit-animation: fadein 0.3s; /* Safari and Chrome */
}



ul.moze-gallery li img {
    transition: opacity 0.3s ease;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@IMPORT url('https: //use.typekit.net/wcg3hlb.css');

#myVideo {
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
}

.card1 {
    position: relative !important;
    -webkit-box-shadow: 2px 2px 6px 2px #f1efef;
    box-shadow: 2px 2px 6px 2px #f1efef;
    text-transform: capitalize !important;
}

.card1:hover {
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(171, 171, 171, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(171, 171, 171, 0);
}

body {
    font-family: montserrat-alternates, sans-serif !important;
    font-weight: 700 !important;
    font-style: normal !important;
}